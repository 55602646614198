<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-bills'"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <v-row dense class="">
              <v-col
                v-for="item in dataFiltered"
                :key="item.productId"
                lg="2"
                md="3"
                sm="4"
                xs="4"
              >
                <v-card height="200" width="150" class="overflow-hidden">
                  <v-img
                    v-if="!item.itemPic"
                    class="white--text align-end"
                    gradient="rgba(248,162,41,.5), rgba(0,0,0,.2)"
                    color="red"
                    width="150"
                    height="100"
                  >
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div style="margin-top: 0px; text-align: center">
                          <v-icon
                            class="text-h1"
                            color="white"
                            id="imageButton"
                            @click="addImage(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-image-plus
                          </v-icon>
                        </div>
                      </template>
                      <span>Add Image</span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="item.isQuickMenu == true"
                      top
                      color="black"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: right;
                            background-color: white;
                            border-radius: 5px;
                          "
                        >
                          <v-icon
                            style="font-size: 35px"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-sale
                          </v-icon>
                        </div>
                      </template>
                      <span> In Promotions</span>
                    </v-tooltip>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.departmentName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>
                  <v-img
                    v-if="item.itemPic"
                    :src="$url + item.itemPic"
                    class="white--text align-end"
                    width="150"
                    height="100"
                  >
                    <v-tooltip
                      v-if="item.isQuickMenu == true"
                      top
                      color="black"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: right;
                            background-color: white;
                            border-radius: 5px;
                          "
                        >
                          <v-icon
                            style="font-size: 35px"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-sale
                          </v-icon>
                        </div>
                      </template>
                      <span> In Promotions</span>
                    </v-tooltip>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.productName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>
                  <router-link
                    v-bind:to="{
                      path: '/admin/admin-products/' + item.productId
                    }"
                    style="text-decoration: none"
                  >
                    <v-card-text style="cursor: pointer" class="black--text">
                      <div class="headerClass">
                        <v-icon style="font-size: 20px" class="mb-1">
                          mdi-food
                        </v-icon>

                        {{ item.productName }}
                      </div>
                      <v-row>
                        <v-col>
                          <div
                            v-if="item.isQuickMenu == false"
                            class="headerClass"
                          >
                            <!-- <v-icon style="font-size:20px" class="mb-1">
                              mdi-currency-usd
                            </v-icon> -->
                            ${{ formatPrice(getRetailPrice(item)) }}
                          </div>

                          <div
                            v-if="item.isQuickMenu == true"
                            class="headerClass"
                          >
                            <!-- <v-icon style="font-size:20px" class="mb-1">
                              mdi-currency-usd
                            </v-icon> -->
                            <span
                              style="text-decoration: line-through; color: red"
                            >
                              ${{ formatPrice(getRetailPrice(item)) }}
                            </span>
                          </div>

                          <div
                            v-if="item.isQuickMenu == true"
                            class="headerClass"
                          >
                            <!-- <v-icon style="font-size:20px" class="mb-1">
                              mdi-currency-usd
                            </v-icon> -->
                            ${{ formatPrice(getDiscountPrice(item)) }}
                          </div> </v-col
                        ><v-col>
                          <div>
                            <qrcode-vue
                              style="float: right"
                              v-if="item.barcode"
                              render-as="canvas"
                              :value="item.barcode"
                              size="29"
                              level="H"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </router-link>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="14" sm="12" md="12">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @click="changePage"
                circle
                color="black"
              ></v-pagination>
            </v-col>
          </v-col>
        </v-row>
        <v-col cols="14" sm="8" md="4" class="float-right mt-n15">
          <v-row dense class="mb-5 float-right">
            <v-tooltip left color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  @click="dialogTrash = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="black"> mdi-delete-restore </v-icon>
                </v-btn>
              </template>
              <span>Trash</span>
            </v-tooltip>
          </v-row></v-col
        >
      </v-container>
      <v-dialog v-model="addDiscountView" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="cancelDiscount">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>

          <v-card-title>
            <span class="headline"> Add Discount Price</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      type="number"
                      class="numberInput"
                      v-model="editedItem.discountPrice"
                      label="Discount Price"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn color="blue darken-1" text @click="cancelDiscount">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="addDiscount">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogViewItem" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogViewItem = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title style="font-size: 20px">
            Product Info
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editItem()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle-edit-outline
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editImage()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-image-plus
                </v-icon>
              </template>
              <span>Edit Image</span>
            </v-tooltip>
          </v-card-title>

          <v-card-title
            style="
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            {{ viewItem.productName }}
          </v-card-title>
          <v-card-title v-if="viewItem.productDescription != ''">
            {{ viewItem.productDescription }}
          </v-card-title>

          <v-divider class="mx-4"></v-divider>

          <v-col md="12">
            <v-row>
              <v-col md="8">
                <v-card-text>
                  <div>Price : {{ formatPrice(viewItem.retailPrice) }}</div>
                  <!-- <div>Dine Price : {{ formatPrice(viewItem.dinePrice) }}</div>
                  <div>
                    Takeaway Price : {{ formatPrice(viewItem.takeawayPrice) }}
                  </div>
                  <div>
                    Delivery Price : {{ formatPrice(viewItem.dinePrice) }}
                  </div> -->
                  <div>
                    Discount Price : {{ formatPrice(viewItem.discountPrice) }}
                  </div>
                </v-card-text>
              </v-col>
              <v-col md="4">
                <v-card-text>
                  <div>
                    <qrcode-vue
                      v-if="viewItem.barcode"
                      render-as="canvas"
                      :value="viewItem.barcode"
                      size="80"
                      level="H"
                    /></div
                ></v-card-text>
              </v-col> </v-row
          ></v-col>
          <v-col md="12">
            <v-row>
              <v-col md="8">
                <v-card-text>
                  <div>
                    Available for Promotions ?
                    <v-radio-group v-model="quickMenuOption" row>
                      <v-radio
                        @click="activateQuickMenu"
                        label="Yes"
                        value="true"
                      ></v-radio>
                      <v-radio
                        @click="activateQuickMenu"
                        label="No"
                        value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="close">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-select
                        v-model="editedItem.subDepartmentId"
                        label="Category"
                        :items="subDepartments"
                        item-text="subDepartmentName"
                        item-value="subDepartmentId"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-select
                        v-model="editedItem.unitId"
                        label="Unit"
                        :items="units"
                        item-text="unitName"
                        item-value="unitID"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.barcode"
                        label="EAN"
                        outlined
                        clearable
                        maxlength="13"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        v-model="editedItem.productName"
                        label="Product Name"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        v-model="editedItem.productDescription"
                        label="Product Description"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col v-if="titleChange == -1" cols="12" class="mb-n8">
                      <v-file-input
                        v-model="editedItem.formFile"
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-camera"
                        label="Image"
                        outlined
                        clearable
                      />
                    </v-col>
                  </v-col>

                  <v-col v-if="titleChange == -1">
                    <v-col  cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.retailPrice"
                        label="Price"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.discountPrice"
                        label="Discount Price"
                        outlined
                        clearable
                      />
                    </v-col>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogImage" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogImage = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline"> Add Image</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="imageForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="viewItem.productName"
                      label="Product"
                      outlined
                      readonly
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      id="imageFile"
                      v-model="editedImage.formFile"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-camera"
                      label="Image"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <!-- <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn> -->
            <v-btn color="blue darken-1" text @click="dialogImage = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="addImageToDb">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-row style="float: right">
            <div>
              <v-icon color="closeButton" @click="dialogTrash = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>

          <v-data-table
            :headers="headers"
            :items="trashed"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:[`item.isInactive`]="{ item }">
              <div>
                <v-chip
                  label
                  style="color: green; width: 50%"
                  v-if="item.isInactive === true"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  style="color: red; width: 50%"
                  v-if="item.isInactive === false"
                >
                  In Active
                </v-chip>
              </div>
            </template>

            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="secondary" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeRestore">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to restore
            {{ editedItem.productName }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeRestore">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete {{ editedItem.productName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete
            {{ editedItem.productName }} permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";

import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      dialogViewItem: false,
      viewItem: [],
      dialogTrash: false,
      dialog: false,
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dateModal: false,
      search: "",

      headers: [
        { text: "Product Id", value: "productId" },
        { text: "Department Id", value: "departmentId" },
        { text: "Sub Department Id", value: "subDepartmentId" },
        { text: "Unit Id", value: "unitId" },
        { text: "Product Name", value: "productName" },
        { text: "", value: "restore" },
        { text: "", value: "delete" }
      ],

      status: [
        { text: "Acitve ", value: true },
        { text: "InActive ", value: false }
      ],
      titleChange: -1,
      editedIndexFiltered: "",
      editedIndexProducts: "",
      editedItem: {
        productName: "",
        productDescription: "",
        subDepartmentId: null,
        departmentId: null,
        unitId: "",
        retailPrice: "",
        takeawayPrice: "",
        dinePrice: "",
        deliveryPrice: "",
        barcode: "",
        formFile: null
      },
      defaultItem: {
        productName: "",
        subDepartmentId: null,
        departmentId: null,
        unitId: "",
        retailPrice: "",
        takeawayPrice: "",
        dinePrice: "",
        deliveryPrice: "",
        barcode: "",
        formFile: null
      },
      trashed: [],
      units: [],
      subDepartments: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 12,
      dataFiltered: [],

      filtered: {
        orderBy: "",
        orderDirection: "desc",
        skip: 0,
        take: 12,
        searchValue: "",
        searchColumn: "",
        clubId: ""
      },
      dialogImage: false,
      editedImage: {
        departmentId: "",
        formFile: ""
      },
      imageFormData: null,
      quickMenuOption: "false",
      addDiscountView: false
    };
  },

  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Product" : "Edit Product";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPermanentDelete(val) {
      val || this.closeDelete();
    },
    dialogRestore(val) {
      val || this.closeRestore();
    },
    page: function() {
      this.changePage();
    }
    // dialogViewItem() {
    //   val || this.viewItem;
    // }
  },

  created() {
    this.getDataFromApi();

    //  this.getFilteredData();
  },

  methods: {
    getRetailPrice(item) {
      return item.retailPrice;
      // if (item.unitId == "1") {
      //   return item.retailPrice * 1000;
      // } else {
      //   return item.retailPrice;
      // }
    },
    getDiscountPrice(item) {
      return item.discountPrice;
      // if (item.unitId == "1") {
      //   return item.discountPrice * 1000;
      // } else {
      //   return item.discountPrice;
      // }
    },
    activateQuickMenu() {
      this.titleChange = 0;
      this.editedItem = Object.assign({}, this.viewItem);

      console.log("activateQuickMenu", this.editedItem);
      if (this.quickMenuOption == "true") {
        // this.editedItem.discountPrice =  this.editedItem.retailPrice;
        this.editedItem.isQuickMenu = true;

        this.addDiscountView = true;
      }
      if (this.quickMenuOption == "false") {
        this.editedItem.isQuickMenu = false;
        //this.editedItem.discountPrice = 0;
        this.save();
      }
    },
    addDiscount() {
      this.addDiscountView = false;
      console.log("addDiscount", this.editedItem);
      // this.editedItem.retailPrice = this.editedItem.retailPrice / 1000;
      // this.editedItem.discountPrice = this.editedItem.discountPrice / 1000;
      this.save();
    },
    cancelDiscount() {
      this.quickMenuOption = "false";
      this.addDiscountView = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDataFromApi() {
      this.isLoading = true;
      this.$http
        .get("/ProductMaster")
        .then(response => {
          this.products = response.data.data;
          this.products = this.products.reverse();
          this.changePage();
        })
        .catch(error => {
          console.log("Error");
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight"
            });
          }
        });

      // this.$http.get("/Product").then(response => {
      //   this.products = response.data.data;
      //   console.log(this.products);
      // });

      this.$http.get("/ProductMaster/GetTrashed").then(response => {
        this.trashed = response.data.data;
      });
      this.$http.get("/Unites/").then(response => {
        this.units = response.data.data;
      });
      this.$http.get("/Menus/").then(response => {
        this.subDepartments = response.data;
      });
    },

    // getFilteredData() {
    //   this.$http
    //     .post("/Product/GetAllByFilter", this.filtered)
    //     .then(response => {
    //       this.dataFiltered = response.data.data;
    //     });
    // },
    updateData() {
      // this.page = 1;
      this.changePage();
    },
    changePage() {
      // var diff = this.page - 1;
      // this.filtered.skip = this.start + this.end * diff;
      // this.getFilteredData();

      var count = this.products.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.products.slice(newStart, newEnd);

      this.dataFiltered = items;
      this.isLoading = false;
    },
    viewEditItem(item) {
      this.editedIndexFiltered = this.dataFiltered.indexOf(item);
      this.editedIndexFilteredProducts = this.products.indexOf(item);
      this.viewItem = Object.assign({}, item);

      if (this.viewItem.isQuickMenu == true) {
        this.quickMenuOption = "true";
      } else {
        this.quickMenuOption = "false";
      }
      // var unitName = this.getUnit(this.viewItem.unitId);
      // if (unitName == "Kg") {
      //   this.viewItem.retailPrice = this.viewItem.retailPrice * 1000;
      //   this.viewItem.discountPrice = this.viewItem.discountPrice * 1000;
      // }

      this.dialogViewItem = true;
    },
    editItem() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.titleChange = 0;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndexFiltered = this.dataFiltered.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedIndexFiltered = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedIndexFiltered = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },

    trashItem() {
      console.log(this.editedItem);
      this.$http
        .post("/ProductMaster/Trash", this.editedItem)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight"
            });
            this.products.splice(this.editedIndexFiltered, 1);
            this.trashed.unshift(this.editedItem);
            this.updateData();
            this.closeDelete();
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    },

    restoreItemConfm() {
      this.$http
        .post("/ProductMaster/Restore", this.editedItem)
        .then(response => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndexFiltered, 1);

            this.products.unshift(response.data.data);

            this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight"
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight"
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var itemId = this.editedItem.productId;
      console.log(itemId);
      console.log("1");
      this.$http
        .delete("/ProductMaster/" + itemId)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success("Deleted Permanently.", "Success", {
              position: "topRight"
            });
            this.trashed.splice(this.editedIndexFiltered, 1);
          }
        })

        .catch(error => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        });

      console.log("3");
      this.closeDelete();
    },
    setDefault() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.titleChange = -1;
      });
    },
    close() {
      this.setDefault();
      this.dialog = false;
    },

    closeDelete() {
      this.setDefault();
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
    },

    closeRestore() {
      this.setDefault();
      this.dialogRestore = false;
    },
    getUnit(id) {
      var unit = this.units.find(e => e.unitID == id);
      if (unit) {
        return unit.unitName;
      } else {
        return "";
      }
    },
    validate() {
      if (
        this.editedItem.productName &&
        this.editedItem.unitId &&
        this.editedItem.retailPrice &&
        this.editedItem.barcode
      ) {
        // var unitName = this.getUnit(this.editedItem.unitId);

        // if (this.editedItem.productDescription == "") {
        //   this.editedItem.productDescription = unitName;
        // }
        // if (unitName == "Kg") {
        //   this.editedItem.retailPrice = this.editedItem.retailPrice / 1000;
        //   this.editedItem.discountPrice = this.editedItem.discountPrice / 1000;
        // }

        if (this.editedItem.barcode.length == 13) {
          this.save();
        } else {
          this.$toast.error("Enter 13 Digit Valid EAN", "Error", {
            position: "topRight"
          });
        }
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight"
        });
      }
    },

    save() {
      if (this.titleChange === -1) {
        this.$http
          .post("/ProductMaster", this.editedItem)
          .then(response => {
            if (response.data.success === true) {
              this.products.unshift(response.data.data);
              this.updateData();
              this.$toast.success("Product added successfully.", "Success", {
                position: "topRight"
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast.error("Adding Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
      } else {
        console.log("discount", this.editedItem);
        var itemId = this.editedItem.productId;
        this.$http
          .put("/ProductMaster/" + itemId, this.editedItem)
          .then(response => {
            if (response.data.success === true) {
              this.viewItem = Object.assign({}, response.data.data);

              // this.products.splice(this.editedIndexFiltered, 1);
              // this.products.unshift(response.data.data);

              this.products.splice(
                this.editedIndexProducts,
                1,
                response.data.data
              );

              this.dataFiltered.splice(
                this.editedIndexFiltered,
                1,
                response.data.data
              );

              this.updateData();

              this.$toast.success("Product Edited successfully.", "Success", {
                position: "topRight"
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast.error("Editing Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
      }
      this.close();
    },
    editImage() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.addImage(this.editedItem);
    },
    addImage(item) {
      this.editedIndexFiltered = this.dataFiltered.indexOf(item);
      this.viewItem = Object.assign({}, item);

      this.editedImage.productId = item.productId;
      this.editedImage.formFile = null;
      this.dialogImage = true;
      // document.getElementById("imageFile").click();
    },
    addImageToDb() {
      this.imageFormData = new FormData();

      this.imageFormData.append("productId", this.editedImage.productId);
      this.imageFormData.append("image", this.editedImage.formFile);

      this.saveImage();
    },
    saveImage() {
      this.isLoading = true;
      this.$http
        .post("/ProductMaster/Image", this.imageFormData)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight"
            });
            this.isLoading = false;
            this.dialogImage = false;

            this.$http.get("/ProductMaster").then(response => {
              this.products = response.data.data;
              this.products = this.products.reverse();
              this.changePage();
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    }
  }
};
</script>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 415px;
  overflow: hidden;
  overflow-y: auto;
}
.addbutton {
  float: right;
}
.temp {
  background-color: rgb(255, 255, 255);
}
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
